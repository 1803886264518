@font-face {
  font-family: 'popinsB';
  src: url('./assets/Poppins-Bold.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'popinsL';
  src: url('./assets/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'popinsM';
  src: url('./assets/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'popinsR';
  src: url('./assets/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'popinsSB';
  src: url('./assets/Poppins-SemiBold.ttf') format('truetype');
}
* {
  font-family: 'Poppins';
}
:root {
  font-size: 14px;
}

.img {
  width: 100%;
  height: auto;
}

.input {
  @apply w-full rounded-md border-solid border-2 border-transparent px-5 py-3 outline-none bg-gray-100  focus:bg-white focus:border-primary;

  &.has-error {
    @apply border-red-600;
  }
}

.button {
  @apply w-full bg-gray-600 text-white border-2 border-transparent rounded cursor-pointer px-5 py-3 focus:ring-4 hover:bg-opacity-80;

  &.is-primary {
    @apply bg-primary text-white;
  }
}

.label {
  @apply block relative;

  + .label {
    @apply mt-4;
  }

  .MuiSelect-select {
    min-width: 150px;
  }
}

.flex-grid {
  @apply flex flex-wrap;

  &.no-space .column {
    &:first-child {
      @apply pl-0;
    }

    &:last-child {
      @apply pr-0;
    }
  }
}

.column {
  flex: 1 0 0%;
  @apply px-1;

  &[class*=' w-'] {
    @apply flex-none;
  }
}

.icon {
  width: 1em;
  height: 1em;
  display: inline-flex;
  align-self: center;
}

section {
  @apply p-2;
}

.section-title {
  @apply w-full font-bold text-lg flex justify-between items-center mb-4;
}

.box-container {
  @apply bg-white p-2 rounded-lg overflow-hidden shadow-sm;
}

.modal-container {
  @apply bg-white rounded-lg overflow-hidden shadow-sm p-10 relative;

  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  // @media (min-width: 992px) {
  //   width: 960px;
  // }
  // @media (min-width: 1200px) {
  //   width: 1140px;
  // }
  // @media (min-width: 1400px) {
  //   width: 1320px;
  // }
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.MuiBadge-badge.MuiBadge-colorPrimary {
  @apply bg-primary;
}

.MuiInputBase-input {
  min-width: 150px !important;
}

.MuiAccordionSummary-content {
  @apply flex items-center justify-between;
}

.MuiDataGrid-root {
  border-color: transparent !important;

  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-columnHeadersInner {
    align-items: stretch !important;
    @apply rounded overflow-hidden;

    .MuiSvgIcon-root {
      @apply text-white;
    }

    .MuiDataGrid-columnHeader {
      @apply bg-gray-600 font-bold text-white;
    }

    .MuiDataGrid-columnHeaderTitle {
      @apply text-lg;
    }
    .MuiDataGrid-iconSeparator {
      display: none;
    }
  }

  .MuiDataGrid-cell {
    border-color: transparent !important;
  }
}

.e-input-group,
.e-input-group.e-control-wrapper {
  width: 270px !important;
  border-bottom: none !important;
}
#nav-links {
  text-decoration: none !important;
  color: #4e575f;
}
.active-link {
  background-color: #f5f5f5;
  color: #76b6b0 !important;
  display: block;
  border-left: 5px solid #76b6b0;
}
.login-button {
  color: #fff;
  text-decoration: none;
}
.login-button:hover {
  color: #fff !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #76b6b0 !important;
}
.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #76b6b0 !important;
}
.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span,
.e-bigger.e-small
  .e-daterangepicker.e-popup
  .e-calendar
  .e-content.e-month
  .e-today.e-range-hover
  span {
  background-color: #eee;
  border: 1px solid #76b6b0 !important;
  color: #76b6b0 !important;
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background-color: #76b6b0 !important;
}
.e-date-range-wrapper .e-input-group-icon.e-icons.e-active {
  color: #76b6b0 !important;
}
.css-nwrfgk-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #76b6b0 !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #76b6b0 !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded,
.css-o4b71y-MuiAccordionSummary-content,
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 35px !important;
}
ul.MuiList-root {
  position: relative;
}
ul.MuiList-root:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 20px;
  height: 77%;
  width: 1px;
  background: #cdcdcd;
}
.MuiDataGrid-columnsContainer {
  background-color: #eeeeee;
}
#arrow-icon {
  background: #eeeeee;
  border-radius: 50%;
  color: #888;
  margin-left: 10px;
}
#save-label {
  margin-left: 0px !important;
}
.e-btn.e-flat.eRoboto-primary:active,
.e-btn.e-flat.e-primary.e-active,
.e-css.e-btn.e-flat.e-primary:active,
.e-css.e-btn.e-flat.e-primary.e-active {
  background-color: rgba(118, 182, 176, 0.24) !important;
  border-color: transparent !important;
  color: #76b6b0 !important;
}
.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  color: #76b6b0 !important;
}
#ej2-datetimepicker_0_popup {
  left: 160px !important;
}
.MuiDataGrid-root {
  font-size: 0.775rem !important;
}
.edit-del-button > button,
.edit-del-button > svg.MuiSvgIcon-fontSizeSmall {
  font-size: 0.775rem !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1em !important;
}
#top-bar-popover .MuiButtonBase-root {
  padding: 5px 10px;
}
.MuiButtonBase-root {
  display: flex !important;
}
.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  border-color: #76b6b0 !important;
  color: #76b6b0;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  min-height: 35px !important;
}
.MuiTablePagination-actions {
  display: flex !important;
}
select option:hover {
  background: #ff9500 -webkit-linear-gradient(bottom, #ff9500 0%, #ff9500 100%);
  color: #fff;
}
.image-upload-box {
  position: relative;
  height: 230px;
}

.Image-diplay img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}
.overlay-shadow {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  display: flex;
  padding-bottom: 20px;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  transition: 0.3s all;
}
.Image-diplay,
.overlay-shadow {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.image-upload-box:hover .overlay-shadow {
  opacity: 1;
}
.image-upload-box input {
  z-index: 2;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  opacity: 0;
}
.Image-diplay {
  z-index: -3;
}
.top-container {
  display: flex;
}
.top-container > div:first-child {
  margin-right: auto;
}
.top-container > div:last-child {
  margin-left: auto;
}

.heading {
  background-color: #4b5563;
}
.heading-item {
  color: white !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}
.row-item {
  color: rgba(0, 0, 0, 0.87) !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
}

.sub-heading {
  background-color: #76b6b0;
  color: white;
}

.upserCase {
  text-transform: capitalize;
}
.pl-custom-0 {
  padding-left: 5px !important;
}
