@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@import url('https://cdn.syncfusion.com/ej2/material.css');
@import 'remixicon/fonts/remixicon.css';

@import './App.scss';

@tailwind components;
@tailwind utilities;
